<template>
  <div class="mobile-week-pagination">
    <button class="button is-blue prev" @click="onChangePage(currentWeek - 1)">
      <svg-icon icon="arrow-down" />
    </button>
    <div class="current-week">
      <p>{{ $t("common.week") }}</p>
      <span>{{ currentWeek }}</span>
    </div>
    <button class="button is-blue next" @click="onChangePage(currentWeek + 1)">
      <svg-icon icon="arrow-down" />
    </button>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex"

export default {
  name: "MobileWeeksPagination",
  data() {
    return {
      currentWeek: 1
    }
  },
  computed: {
    ...mapState("plan", ["result"])
  },
  methods: {
    ...mapActions("plan", ["changeActiveWeek"]),
    onChangePage(value) {
      if (value >= 1 && value <= this.result.totalWeeks) {
        this.currentWeek = value
        this.changeActiveWeek(value)
      }
    }
  }
}
</script>
<style scoped lang="scss">
.icon-arrow-down {
  scale: 2;
}
.mobile-week-pagination {
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  span {
    font-size: 32px;
  }
}
.button {
  display: flex;
  justify-content: center;
  width: auto;
  margin-bottom: 3px;
  .icon {
    margin: 0 !important;
  }
}
.next {
  .icon {
    transform: rotate(-90deg);
  }
}
.prev {
  .icon {
    transform: rotate(90deg);
  }
}
.current-week {
  display: flex;
  align-items: center;
  flex-direction: column;
}
</style>
