<template>
  <BlueHeaderModal @close="$emit('close')">
    <template slot="header">
      <h3 class="plan-subtitle">{{ title }}</h3>
    </template>

    <template slot="body">
      <div class="collapse-info">
        <p>{{ description }}</p>
        <iframe
          width="100%"
          class="youtube-iframe"
          :src="link"
          title="YouTube video player"
          style="border-radius:10px"
          allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <a target="_blank" href="https://trainerday.com/">{{ $t("common.readMore") }}</a>
      </div>
    </template>
  </BlueHeaderModal>
</template>
<script>
export default {
  name: "HowItWorksModal",
  props: {
    title: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    },
    link: {
      type: String,
      default: ""
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/mixins.scss";

.collapse-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 0 20px 20px 20px;
  $lines-count: 12;
  $line-height: 1.5em;

  a {
    margin-top: 16px;
    text-decoration: underline;
    font-size: 16px;
  }
}
::v-deep {
  .blue-header-modal-header {
    padding: 20px;
    .field {
      margin: 0;
    }
  }
}

h3 {
  color: $white;
  font-size: 18px;
}

.youtube-iframe {
  margin-top: 16px;
  min-height: 400px;
}
</style>
