<template>
  <div class="cj-field-content-right-modals">
    <a
      v-if="isEventBasedPlan && showDateInput"
      href="#"
      class="link-button is-underline"
      @click.prevent="showMultipleEventsModal = true"
    >
      {{ multipleEventsLink }}
    </a>
    <a v-if="!showDateInput" href="#" class="link-button is-underline" @click.prevent="showMultipleEventsModal = true">
      {{ multipleEventsLink }} ({{ events.length }})
    </a>
    <MultipleEvents v-if="showMultipleEventsModal" @close="showMultipleEventsModal = false" @onSave="onSaveEvents" />
    <a href="#" class="link-button is-underline" @click.prevent="showDaysOffModal = true">
      {{ $t("planDuration.plannedDaysOff") }}
    </a>
    <DaysOff
      v-if="showDaysOffModal"
      @close="showDaysOffModal = false"
      :dateRange="profileDaysOff"
      @saveDaysOff="saveDaysOff"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import DaysOff from "@/components/plan/MainArea/Settings/DaysOff.vue"
import MultipleEvents from "@/components/plan/PlanDurationAndEventDate/MutlipleEvents.vue"

export default {
  name: "EventsAndDaysOff",
  components: {
    DaysOff,
    MultipleEvents
  },
  data() {
    return {
      userMultipleEvents: [],
      showDaysOffModal: false,
      showMultipleEventsModal: false
    }
  },
  methods: {
    ...mapActions("plan", ["updatePlanConfigValue"]),

    onSaveEvents(events) {
      this.updatePlanConfigValue({ name: "events", value: events })
      this.showMultipleEventsModal = false
    },
    saveDaysOff(data) {
      this.updatePlanConfigValue({ name: "daysOff", value: data })
      this.showDaysOffModal = false
    }
  },

  computed: {
    ...mapGetters("plan", ["getPlanConfigValue", "firstPrimaryEvent", "getPlanConfigValue"]),
    multipleEventsLink() {
      if (this.events.length === 1) {
        return this.$t("stepPlan.addEvents")
      } else {
        return this.$t("stepPlan.editEvents")
      }
    },
    showDateInput() {
      return (
        this.events.length === 1 && this.events.filter(event => event.eventDate !== this.firstPrimaryEvent.eventDate)
      )
    },
    isEventBasedPlan() {
      return this.getPlanConfigValue("isEventBasedPlan")
    },
    profileDaysOff() {
      return this.getPlanConfigValue("daysOff")
    },
    events() {
      const profileMultipleEvents = this.getPlanConfigValue("events")

      if (profileMultipleEvents && profileMultipleEvents.length) {
        return profileMultipleEvents.sort((a, b) => new Date(a.eventDate) - new Date(b.eventDate))
      } else {
        return this.firstPrimaryEvent ? [this.firstPrimaryEvent] : []
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/mixins.scss";
@import "@/assets/styles/theme.scss";

.cj-field-content-right-modals {
  display: flex;
  justify-content: flex-end;
  gap: 10px;

  .link-button {
    font-size: 12px;
    color: #8c9fca;
    @include media("<tablet") {
      white-space: nowrap;
      height: 42px;
      padding: 12px;
      border-color: unset;
      background-color: $dark-blue;
      border-radius: 10px;
      color: white;

      @include on-event() {
        background-color: $blue;
        border-color: unset;
        color: #fff;
      }
    }
  }
  @include media("<tablet") {
    justify-content: space-between;
  }
}
</style>
