<template>
  <div class="workout-chart" v-show="hasData"></div>
</template>

<script>
import Vue from "vue"
import "d3-transition"
import workoutChartMain from "../../../../mixins/workoutChartMain"
import workoutChartFullWidth from "@/mixins/workoutChartFullWidth"

export default Vue.extend({
  name: "ChartSimple",
  mixins: [workoutChartMain, workoutChartFullWidth]
})
</script>
