<template>
  <GChart
    class="zone-chart"
    :settings="{ packages: ['corechart', 'bar'] }"
    type="BarChart"
    :data="chartData"
    :options="chartOptions"
    :resizeDebounce="100"
  />
</template>
<script>
import { GChart } from "vue-google-charts"
import getColorByTimeZoneName from "@/services/getColorByTimeZoneName"

export default {
  name: "ZonesChart",
  components: {
    GChart
  },
  data() {
    return {
      // {
      //    eventName: handlerFunction,
      //    eventName: handlerFunction,
      // }
      chartEvents: {
        ready: e => {
          console.log(e)
        }
      }
    }
  },
  props: {
    /** if true then sets short titles to vAxis **/
    shortTitles: {
      type: Boolean,
      default: false
    },
    zonesData: {
      type: Object,
      default: () => ({})
    },
    chartOptions: {
      type: Object,
      default: () => ({
        legend: "none",
        titleTextStyle: { fontName: "Poppins", color: "#2b2b2b" },
        chartArea: {
          left: 100,
          top: 0,
          width: "calc(100% - 10px)"
        },
        width: "100%",
        height: 330,
        bar: { groupWidth: "75%" },
        hAxis: {
          title: "Minutes",
          gridlines: { color: "#f2f2f2" },
          textStyle: { fontName: "Poppins", fontSize: 12, color: "#2b2b2b", opacity: 0.25 },
          titleTextStyle: { fontName: "Poppins", fontSize: 12, color: "#2b2b2b", opacity: 0.25 },
          minValue: 0
        },
        vAxis: {
          textStyle: { fontName: "Poppins", fontSize: 12 }
        },
        backgroundColor: "white"
      })
    }
  },
  computed: {
    chartData() {
      const short = this.shortTitles
      return [
        ["Element", "", { role: "style" }],
        [short ? "Recovery" : "Zone 1\n Recovery", this.zonesData.z1, getColorByTimeZoneName("Recovery")],
        [short ? "Endurance" : "Zone 2\n Endurance", this.zonesData.z2, getColorByTimeZoneName("Endurance")],
        [short ? "TempoBlock" : "Zone 3\n Tempo", this.zonesData.z3, getColorByTimeZoneName("TempoBlock")],
        [short ? "Threshold" : "Zone 4\n Threshold", this.zonesData.z4, getColorByTimeZoneName("Threshold")],
        [short ? "VO2 Max" : "Zone 5\n VO2 Max", this.zonesData.z5, getColorByTimeZoneName("VO2 Max")],
        [short ? "Anaerobic" : "Zone 6\n Anaerobic", this.zonesData.z6, getColorByTimeZoneName("Anaerobic")]
      ]
    }
  }
}
</script>
<style lang="scss">
.zone-chart {
  // Anaerobic label
  & > div > div:nth-child(1) > div > svg > g:nth-child(3) > g:nth-child(4) > g:nth-last-child(1) > text {
    fill: #000000;
  }
  // VO2 Max label
  & > div > div:nth-child(1) > div > svg > g:nth-child(3) > g:nth-child(4) > g:nth-last-child(2) > text {
    fill: #fe5959;
  }
  // Threshold label
  & > div > div:nth-child(1) > div > svg > g:nth-child(3) > g:nth-child(4) > g:nth-last-child(3) > text {
    fill: #ffc562;
  }
  // Tempo label
  & > div > div:nth-child(1) > div > svg > g:nth-child(3) > g:nth-child(4) > g:nth-last-child(4) > text {
    fill: #8857e4;
  }
  // Endurance label
  & > div > div:nth-child(1) > div > svg > g:nth-child(3) > g:nth-child(4) > g:nth-last-child(5) > text {
    fill: #61a7e8;
  }
  // Recovery label
  & > div > div:nth-child(1) > div > svg > g:nth-child(3) > g:nth-child(4) > g:nth-last-child(6) > text {
    fill: #4ddd8a;
  }
}
</style>
