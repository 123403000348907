<template>
  <div class="event">
    <img class="jack-hero-img" src="@/assets/images/flag-event.svg" alt="Finish" />
    <div>{{ $t("common.event") }}</div>
    <span>{{ date }}</span>
    <span>{{ eventData.eventDuration }} {{ eventData.eventType }}</span>
  </div>
</template>

<script>
import { mapState } from "vuex"
import moment from "moment"

export default {
  name: "EventItem",
  props: {
    eventData: {
      eventDuration: String,
      eventDate: String,
      eventType: String
    }
  },
  computed: {
    ...mapState(["profileFacts"]),
    date() {
      return moment(this.eventData.eventDate, "YYYY-MM-DD").format("Do MMM, YYYY")
    }
  }
}
</script>

<style scoped lang="scss">
.event {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffc700;
  border: 2px solid rgba(255, 255, 255, 0.7);
  border-radius: 5px;
  padding: 7px 0;

  img {
    max-width: 30px;
  }

  div {
    font-weight: 500;
    font-size: 14px;
    text-transform: capitalize;
  }

  span {
    font-size: 12px;
    color: #131415;
    opacity: 0.5;
  }
}
</style>
