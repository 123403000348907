<template>
  <div class="no-training">
    <!--    No Training-->
    <!--    <svg-icon icon="no-day-arrow" />-->
    <span class="weekday">{{ day }}</span
    >{{ $t("calendar.rest") }}
  </div>
</template>
<script>
export default {
  name: "NoTrainingDay",
  props: {
    day: {
      type: String,
      default: ""
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/variables";
@import "@/assets/styles/mixins";

.no-training {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: $gray15;
  font-size: 13px;
  font-weight: 500;
  min-height: 92px;

  .icon {
    margin: 8px;
  }
  @include media("<tablet") {
    width: 100%;
  }
}
</style>
<style scoped lang="scss">
@import "@/assets/styles/variables";
@import "@/assets/styles/mixins";

.weekday {
  text-transform: uppercase;
  display: none;
  @include media("<tablet") {
    display: initial;
  }
}
</style>
