<template>
  <div class="day">
    <div class="no-training-sick ">
      <span class="weekday">{{ day }}</span
      >{{ $t("calendar.rest") }}
    </div>
    <div class="days-off">
      <span v-for="index in watermarkCount" :key="index" class="days-off-text"
        >{{ $t("planDuration.daysOffText") }} {{ $t("planDuration.daysOffText") }} {{ $t("planDuration.daysOffText") }}
        {{ $t("planDuration.daysOffText") }} {{ $t("planDuration.daysOffText") }}
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: "NoTrainingDaySick",
  props: {
    day: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      watermarkCount: 10
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/mixins";
.day {
  position: relative;
  height: 100%;
}
.no-training-sick {
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  color: $gray15;
  font-weight: 500;
  font-size: 13px;
  .icon {
    margin: 8px;
  }
}
.days-off {
  display: grid;
  grid-template-columns: repeat(10, 40px);
  position: absolute;
  height: inherit;
  justify-items: start;
  overflow: hidden;
  grid-gap: 10px;
  opacity: 0.2;
  width: 100%;

  &-text {
    display: inline-block;
    writing-mode: vertical-lr;
    transform: rotate(225deg);
    white-space: nowrap;
    font-size: 24px;
    margin-top: -125px;
    margin-left: -170px;
    letter-spacing: 2.5px;
    z-index: 2;
  }
}
.weekday {
  text-transform: uppercase;
  display: none;
  @include media("<tablet") {
    display: initial;
  }
}
</style>
