<template>
  <div class="header">
    <div class="header-hello">
      <h2 class="header-title">
        <span>{{ $t("common.hello") }} {{ name }}!</span> <span class="header-hand">👋</span>
        <span class="custom-br" />{{ $t("stepPlan.here") }}
      </h2>
      <div class="header-help">
        <span @click="showHowItWorksModal = true"
          >{{ $t("common.help") }}
          <img class="jack-hero-img" src="@/assets/images/q.svg" alt="FAQ" />
        </span>
        <router-link to="/jack"
          >{{ $t("common.questions") }}
          <img class="jack-hero-img" src="@/assets/images/plan_qs.svg" alt="FAQ" />
        </router-link>
      </div>
    </div>
    <HowItWorksModal
      v-if="showHowItWorksModal"
      @close="showHowItWorksModal = false"
      :title="howItWorks.title"
      :description="howItWorks.description"
      :link="howItWorks.link"
    />
  </div>
</template>

<script>
import HowItWorksModal from "@/components/plan/Header/HowItWorksModal.vue"
export default {
  name: "index",
  components: { HowItWorksModal },
  props: {
    name: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      showHowItWorksModal: false,
      howItWorks: {
        title: this.$t("howItWorks.title"),
        description: this.$t("howItWorks.description"),
        link: "https://www.youtube.com/embed/AZEGoa9p-iU"
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/mixins.scss";
.header {
  &-hello {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    margin-bottom: 12px;
    @include media("<desktop") {
      margin-bottom: 0;
    }
    @include media("<=900px") {
      height: unset;
      flex-direction: column-reverse;
      align-items: center;
    }
  }
  &-hand {
    font-size: 18px;
    line-height: 36px;
    margin-right: 5px;
    align-self: flex-start;
  }

  &-title {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;

    span {
      color: $dark-blue;
    }
    @include media("<=900px") {
      text-align: center;
      margin-top: 12px;
    }
  }

  &-subtitle {
    font-size: 12px;
    color: $gray11;
    font-weight: 500;
    margin-bottom: 20px;

    @include media("<tablet") {
      margin-bottom: 0;
      margin-top: 10px;
    }
  }
  &-help {
    display: flex;
    flex-direction: row;
    grid-gap: 20px;
    span,
    a {
      color: $dark-blue;
      text-decoration: underline;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      grid-gap: 10px;
    }
    @include media("<=900px") {
      margin-left: auto;
    }
  }
}
</style>
