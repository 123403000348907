<template>
  <div class="tabs is-right">
    <ul>
      <li>{{ $t("common.weeks") }}</li>
      <li
        v-for="([start, end], index) in getWeekSets"
        :key="index"
        :class="[{ 'is-active': start === view.activeWeek }]"
      >
        <a @click.prevent="() => changeActiveWeek(start)">
          <span>{{ start !== end ? `${start} - ${end}` : start }}</span>
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex"

export default {
  name: "WeeksSelector",
  computed: {
    ...mapState("plan", ["view", "result"]),

    getWeekSets() {
      if (!this.result?.totalWeeks) {
        return []
      }

      const fullSets = Math.floor(this.result.totalWeeks / 4)
      const last = this.result.totalWeeks - fullSets * 4
      const sets = Array.from({ length: fullSets }).map((_, e) => [e * 4 + 1, e * 4 + 4])
      let lastSet = []
      if (last) {
        lastSet = [[fullSets * 4 + 1, fullSets * 4 + last]]
      }
      return [...sets, ...lastSet]
    }
  },
  methods: {
    ...mapActions("plan", ["changeActiveWeek"])
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables";

.tabs {
  ul {
    border: none;
    li {
      text-transform: capitalize;
    }
  }

  a {
    border-bottom-color: $light-blue3;
    border-bottom-width: 3px;
  }
}

.tabs.is-right li.is-current:not(.is-active) a {
  color: #71b4fe;
}
</style>
