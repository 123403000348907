<template>
  <BlueHeaderModal @close="$emit('close')">
    <template slot="header">
      <label class="label">{{ title }}</label>
      <p></p>
      <div class="has-text-white">Best for {{ type }}</div>
    </template>
    <template slot="body">
      <div v-html="description"></div>
    </template>
  </BlueHeaderModal>
</template>

<script>
import BlueHeaderModal from "../../../BlueHeaderModal"
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    }
  },
  name: "IndoorOutdoorModal",
  components: {
    BlueHeaderModal
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables";
@import "@/assets/styles/mixins";

.label {
  text-transform: uppercase;
}

::v-deep {
  .blue-header-modal-container {
    margin-top: 60px;
    max-width: 320px;
    min-width: 320px;
  }
  .blue-header-modal-header {
    padding: 30px 30px 10px;
    width: 100%;

    @include media("<phone") {
      padding: 20px;
    }
  }

  .blue-header-modal-body {
    display: flex;
    flex-direction: column;
    padding: 10px 25px 30px;
    gap: 30px;

    @include media("<phone") {
      padding: 20px;
    }
  }

  .blue-header-modal-footer {
    display: none;
  }
}
</style>
