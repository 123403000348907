<template>
  <div class="stat">
    <svg-icon :icon="icon"></svg-icon>
    {{ value }}
  </div>
</template>
<script>
export default {
  name: "WorkoutStat",
  props: ["value", "icon"]
}
</script>
<style>
.stat {
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: 500;
  color: #131415;
}
</style>
