<template>
  <section class="plan-footer-wrapper">
    <div class="container">
      <div class="plan-footer">
        <div class="plan-footer-text">{{ $t("footer.text1") }} {{ $t("footer.text2") }}</div>
        <div class="plan-footer-buttons">
          <router-link :to="{ name: 'history' }" class="plan-footer-cancel">{{ $t("footer.cancel") }}</router-link>
          <button @click="showPushModal = true" class="button is-green create-plan">
            <svg-icon icon="save" />
            <span>{{ $t("footer.saveNext") }}</span>
          </button>
        </div>
      </div>
    </div>

    <BaseModal
      v-if="showRedirectToTrainerDayModal && planSlug"
      :title="$t('footerRedirectModal.title')"
      @close="closeRedirectModal"
    >
      <h3 slot="body">{{ $t("footerRedirectModal.text") }}</h3>
      <div slot="save-button">
        <a @click="closeRedirectModal" :href="`${appUrl}/plans/`" target="_blank" class="external button is-red">
          {{ $t("footerRedirectModal.go") }}
        </a>
      </div>
    </BaseModal>
    <SavePlanModal v-if="showPushModal" @close="showPushModal = false" @redirect="redirect($event)" />
  </section>
</template>

<script>
import BaseModal from "@/components/BaseModal"
import { mapState } from "vuex"
import configuration from "@/configuration"
import SavePlanModal from "@/components/plan/Footer/SavePlanModal.vue"

const { appUrl } = configuration

export default {
  name: "PlanFooter",
  components: {
    SavePlanModal,
    BaseModal
  },
  data() {
    return {
      planSlug: undefined,
      showRedirectToTrainerDayModal: false,
      appUrl,
      showPushModal: false
    }
  },
  computed: {
    ...mapState("plan", ["plan"])
  },
  methods: {
    closeRedirectModal() {
      this.showRedirectToTrainerDayModal = false
      this.$router.push({ name: "history" })
    },
    async redirect(e) {
      const { isEmbedded, planSlug, destination } = e
      this.planSlug = planSlug
      if (isEmbedded) {
        let type
        if (destination === "plans") {
          type = "open-plan"
        } else {
          type = "open-calendar"
        }
        window.parent.postMessage(
          {
            type,
            planSlug: this.planSlug
          },
          appUrl
        )
      } else {
        this.showRedirectToTrainerDayModal = true
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/mixins.scss";

.plan {
  &-footer {
    &-wrapper {
      position: fixed;
      bottom: 0;
      width: 100%;
      z-index: 2001;

      @include media("<desktop") {
        & > .container {
          padding: 0;
        }
      }
    }

    padding: 15px 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: 0px -12px 20px rgba(0, 0, 0, 0.15);
    transition: height 0.15s ease-in;
    background-color: white;
    position: relative;

    @include media("<desktop") {
      flex-direction: column;
      padding: 0 14px 14px 14px;
      justify-content: center;
    }

    @include media("<=tablet") {
      padding: 14px;
      justify-content: center;
    }

    &:not(.v-show-additional) &-additional {
      display: flex;
      @include media("<desktop") {
        overflow-y: hidden;
        max-height: 0;
        margin-top: -10px;
      }
    }

    &-text {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      transition: all 0.2s ease-in-out;
      max-height: 300px;

      @include media("<desktop") {
        flex-direction: column;
        margin-bottom: 12px;

        & > p {
          margin-bottom: 12px;
        }
      }
    }

    &-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-cancel {
      color: black;
      text-decoration: underline;
      margin-right: 70px;
      text-transform: uppercase;
    }

    &-stick-container {
      display: none;
      padding: 10px;
      cursor: pointer;

      .footer-stick {
        background: $gray17;
        width: 54px;
        height: 3px;
        border-radius: 64px;
      }

      @include media("<desktop") {
        display: block;
      }
    }
  }
}

.create-plan {
  font-weight: 500;
  //min-width: 345px;
  margin-left: 12px;
  padding: 0 30px;

  @include media("<desktop") {
    margin-left: 0;
  }

  & > * + * {
    margin-left: 15px;
  }

  b {
    text-decoration: underline;
  }
}

.v-hidden {
  visibility: hidden;
}
.save-draft {
  padding: 0 25px;
  display: flex;
  gap: 5px;
  background-color: $orange2;
  color: white;

  @include on-event() {
    background-color: #ff9f00cc;
    border-color: unset;
    color: #fff;
  }
}

.hidden {
  display: none;
}
</style>
